<template>
  <div
    class="df"
    style="height: 1261px; padding: 20px; background-color: #48484b"
  >
    <div
      style="
        flex: 0 0 300px;
        height: 100%;
        padding: 10px;
        border: 1px solid #fff;
        border-radius: 20px;
        flex-direction: column;
        flex-flow: column;
        background-color: #18181c;
      "
      class="df"
    >
      <div
        style="padding: 10px; color: #fff; font-size: 30px; font-weight: bolder"
        class="df aic jcb"
      >
        <span>顺序列表</span>
        <el-popover
          placement="bottom"
          :visible-arrow="false"
          :width="120"
          trigger="hover"
          popper-class="popover-box"
        >
          <div class="btn-box">
            <div class="add-btn df aic jcc" @click="addImg">图片</div>
            <div class="add-btn df aic jcc" @click="dialogVisible = true">
              传感器
            </div>
          </div>
          <div slot="reference" class="add-btn df aic jcc">+</div>
        </el-popover>
      </div>
      <div class="sort-box">
        <div
          class="df sort"
          v-for="(item, index) in chooseData"
          :key="index"
          :class="item.ischecked ? 'checked' : ''"
          @click="checkSortItem(index)"
        >
          <div class="index-box df aic jcc" v-if="item.isSort">
            {{ item.index }}
          </div>
          <div v-else class="index-box-none df aic jcc"></div>
          <div class="title-box">
            {{ item.title }}
          </div>
          <div
            class="active-icon df aic jcc"
            @click="item.isActive = !item.isActive"
          >
            <div class="hover" v-if="item.isActive">
              <img src="@/assets/img/unlock-icon-hover.png" alt="" />
            </div>
            <div class="hover" v-else>
              <img src="@/assets/img/lock-icon-hover.png" alt="" />
            </div>
            <div class="not-hover" v-if="item.isActive">
              <img src="@/assets/img/unlock-icon.png" alt="" />
            </div>
            <div class="not-hover" v-else>
              <img src="@/assets/img/lock-icon.png" alt="" />
            </div>
          </div>
          <div
            class="del-icon active-icon df aic jcc"
            @click="delSortItem(index)"
          >
            <div class="hover">
              <img src="@/assets/img/del-icon-hover.png" alt="" />
            </div>
            <div class="not-hover">
              <img src="@/assets/img/del-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        flex: 1;
        margin-right: 20px;
        margin-left: 20px;
        border: 1px solid #fff;
        border-radius: 20px;
      "
    ></div>
    <div
      style="
        flex: 0 0 300px;
        border: 1px solid #fff;
        border-radius: 20px;
        flex-direction: column;
        background-color: #18181c;
      "
    ></div>
    <el-dialog title="传感器" :visible.sync="dialogVisible" width="50%">
      <div>
        <el-transfer
          style="text-align: left; display: inline-block"
          v-model="transferData"
          filterable
          :render-content="renderFunc"
          :titles="['备选列表', '已选择']"
          :button-texts="['移除', '添加']"
          :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}',
          }"
          @change="handleChange"
          :data="sensorInfo"
        >
        </el-transfer>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sensorChange">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../../util/extra-api";

export default {
  data() {
    return {
      chooseData: [],
      info: {
        x: 800,
        y: 600,
        backgroundUrl: "",
        backgroundColor: "",
      },
      sensorInfo: [],
      dialogVisible: false,
      renderFunc(h, option) {
        return <span>{option.label}</span>;
      },
      transferData: [],
      checkedIndex: {
        flag: false,
        data: [],
      },
    };
  },
  computed: {
    newIndex() {
      if (this.chooseData.length) {
        let countNum = 0;
        this.chooseData.forEach((v) => {
          if (v.isSort) {
            countNum++;
          }
        });
        return countNum + 1;
      } else {
        return 1;
      }
    },
  },
  created() {
    this.getSensor();
    this.chooseData = [];
    // for (let i = 0; i < 8; i++) {
    //   this.chooseData.push({
    //     index: this.newIndex,
    //     title: "标题" + this.newIndex,
    //     isActive: true,
    //     type: "sensor",
    //     ischecked: false,
    //     isSort: true,
    //     imgInfo: {
    //       url: "",
    //       width: 100,
    //       height: 100,
    //       degree: 0,
    //     },
    //     x: 100,
    //     y: 100,
    //   });
    // }
  },
  methods: {
    getSensor() {
      api.get(`/v1/wx/device/fsSensorChart?companyId=2582225`).then((res) => {
        if (res.code === 200) {
          // console.log(res);
          this.sensorInfo = res.data.map((v, i) => {
            return {
              key: i,
              label: v.name,
              disabled: false,
              id:v.id
            };
          });
        }
      });
    },
    sensorChange() {
      if (this.checkedIndex.flag) {
        let idsData=this.checkedIndex.data.map(v=>this.sensorInfo[v].id)
        this.chooseData.forEach((v,i)=>{
          if(!idsData.includes(v.id)){
            console.log(v,'已移除');
            this.delSortItem(i)
          }
        })
        this.checkedIndex.data.forEach((v) => {
          if (
            this.chooseData.filter((item) => item.id === this.sensorInfo[v].id).length
          ) {
          } else {
            this.chooseData.push({
              index: this.newIndex,
              title: this.sensorInfo[v].label,
              id: this.sensorInfo[v].id,
              isActive: true,
              type: "sensor",
              ischecked: false,
              isSort: true,
              imgInfo: {
                url: "",
                width: 100,
                height: 100,
                degree: 0,
              },
              x: 100,
              y: 100,
            });
          }
        });
        this.dialogVisible=false;
        this.checkedIndex.flag = false;
      }
    },
    addImg() {
      this.chooseData.push({
        index: null,
        title: "图片" + (this.chooseData.length + 1),
        isActive: true,
        type: "image",
        ischecked: false,
        isSort: false,
        imgInfo: {
          url: "",
          width: 100,
          height: 100,
          degree: 0,
        },
        x: 100,
        y: 100,
      });
    },
    checkSortItem(index) {
      this.chooseData.forEach((v, i) => {
        v.ischecked = i === index;
      });
    },
    delSortItem(index) {
      this.chooseData.forEach((v, i) => {
        if (i > index && v.index !== null) {
          v.index = v.index - 1;
        }
      });
      this.chooseData = this.chooseData.filter((v, i) => i !== index);
    },
    handleChange(value, direction, movedKeys) {
      this.checkedIndex.flag = true;
      this.checkedIndex.data = value;
      // console.log(value, direction, movedKeys);
    },
  },
};
</script>

<style lang='scss' scoped>
.box {
  flex-direction: column;
  background-color: #18181c;
  background-color: #232324;
}
.sort-box {
  user-select: none;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  .sort {
    padding: 10px 10px 10px 20px;
    background-color: #232324;
    border-radius: 10px;
    margin-bottom: 10px;
    .index-box {
      background-color: #fff;
      color: #5b4989;
      font-size: 20px;
      height: 24px;
      flex: 0 0 24px;
      font-weight: bolder;
      border-radius: 6px;
    }
    .index-box-none {
      height: 24px;
      flex: 0 0 24px;
      background-color: none;
    }
    .title-box {
      flex: 1;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #fff;
      line-height: 24px;
      margin: 0 10px;
    }
    .active-icon {
      flex: 0 0 24px;
      height: 24px;
      border-radius: 6px;
      color: #fff;
      img {
        margin: 10%;
        width: 80%;
        height: 80%;
        object-fit: cover;
      }
      .not-hover {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .hover {
        display: none;
      }
    }
    .del-icon {
      display: none;
    }
  }
  .checked {
    background-color: rgba(0, 129, 255, 0.6) !important;
  }
  .sort:hover {
    background-color: #414142;
    .active-icon:hover {
      background-color: #fff;
      color: red;
      cursor: pointer;

      .not-hover {
        display: none;
      }
      .hover {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .del-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }
  }
}
.sort-box::-webkit-scrollbar-track {
  background: #18181c;
  border-radius: 0;
}

.sort-box::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.sort-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  transition: color 0.2s ease;
  cursor: pointer;
}
.jcb {
  justify-content: space-between;
}
.add-btn {
  width: 40px;
  height: 30px;
  border-radius: 10px;
}
.add-btn:hover {
  background-color: #414142;
  cursor: pointer;
}
.btn-box {
  .add-btn {
    width: 100%;
    height: 40px;
  }
}

/deep/.el-transfer-panel {
  width: 340px !important;
  .el-transfer-panel__body {
    height: 600px;
  }
  .el-checkbox-group {
    height: 554px;
  }
}
</style>
<style>
.popover-box {
  background: #18181c !important;
  width: 150px;
  padding: 5px !important;
  color: #fff;
  font-size: 20px;
  font-weight: bold !important;
  border-radius: 15px !important;
  border: 1px solid #555 !important;
}
</style>
